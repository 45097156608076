<template>
  <base-section id="testimony">
   <v-container fluid>
    <v-row dense>
      <v-col
      	col="12"
        md="12"
      >
      <base-section-heading title="Apa Kata Peserta Tentang Kami" />
	    <v-sheet
	    max-width="100%"
	  >
	    <v-slide-group
	      v-model="testimoni"
	      center-active
	      class="pa-1"
	      show-arrows
	      prev-icon="mdi-arrow-left-circle"
      	  next-icon="mdi-arrow-right-circle"
	    >
	      <v-slide-item
	        v-for="slide in slides"
	        :key="slide.title"
	      >
        <v-card
	    class="mr-2"
	    color="secondary"
	    dark
	    :max-width="$vuetify.breakpoint.xs ? 220: 500"
	  >
	    <v-card-title>
	      <v-icon
	        large
	        left
	      >
	        mdi-comment-quote
	      </v-icon>
	      <span class="title font-weight-light">Testimoni</span>
	    </v-card-title>

	    <v-card-text class="body-2 font-weight-bold">
	      <v-icon>mdi-format-quote-open</v-icon>{{ slide.subtitle }}<v-icon>mdi-format-quote-close</v-icon>
	      <div v-if="$vuetify.breakpoint.xs" class="primary--text">{{ slide.title }}<br>
	      	<v-img
	            :src="require(`@/assets/${slide.src}`)"
	            contain
	            height="300"
	          ></v-img>
		</div>
	    </v-card-text>

	    <v-card-actions v-if="$vuetify.breakpoint.xs === false">
	      <v-list-item class="grow">
	        <v-list-item-avatar color="grey darken-3" :size="$vuetify.breakpoint.xs ? 100 : 62">
	          <v-img
	            class="elevation-6 cursor"
	            :src="require(`@/assets/${slide.src}`)"
	            contain
	            @click = "Zoom(slide.title,slide.src)"
				:alt="slide.title"
	          ></v-img>
	        </v-list-item-avatar>
	        <v-list-item-content>
	          <v-list-item-title>{{ slide.title }}</v-list-item-title>
	        </v-list-item-content>
	        
	      </v-list-item>
	    </v-card-actions>
	  </v-card>
   </v-slide-item>
</v-slide-group>
</v-sheet>
</v-col>
</v-row>
</v-container>
<!-- Dialog Zoom In -->
<v-dialog
  v-model="zoomin.value"
  width="500"
>
<v-card>
    <v-card-title class="body-2 grey lighten-3 secondary--text">
      {{ zoomin.title }}
    </v-card-title>
    <v-card-text>
      <v-img
        :src="require(`@/assets/${zoomin.src}`)"
        height="100%"
        cover
      ></v-img>
    </v-card-text>
</v-card>
</v-dialog>
</base-section>
</template>

<script>
  export default {
    name: 'SectionTestimony',

    data: () => ({
    	testimoni: null,
    	zoomin: {
    		value: false,
    		title: '',
    		src: 'logo-big.png'
    	},
        slides: [
        	{title: 'Bapak H. Husen – Karyawan Swasta', subtitle: 'Takaful Abror, Produk yang sangat bagus, saya sudah menggunakan produkini selama 2 tahun terakhir, bahkan pelayanan klaimnya juga sangat cepat dan saya bisa menggunakan layanan bengkel resmi dimana saja.', src: 'testimoni-bapakhusen-abror.jpeg'},
        	{title: 'Ibu Irma – Pengusaha Travel Wisata', subtitle: 'Produk Takaful Safari sangat bagus, memiliki banyak manfaat dan harganya yang sangat murah, sehingga beberapa kali customer saya tidak keberatan jika harus dicover langsung dengan asuransi tersebut, selain murah, pelayanan klaimnya pun sangat cepat.', src: 'testimoni-ibu-irma-safari.jpeg'},
        	{title: 'Rifki Kurniawan - Mahasiswa', subtitle: 'Selama covid 19 mendapatkan santunan dari Takaful Umum, dan saya mengucapkan terimakasih banyak dengan santunan ini dapat memenuhi kebutuhan saya, kedepannya Takaful Umum lebih sukses banyak peminat dan Asuransi Kecelakaan Diri di Takaful.', src: 'testimoni-rifki-kecelakaandiri.jpeg'},
        	{title: 'Ibu Nidya – Pengusaha', subtitle: 'Produk Surgaina saya dapat dari sosial media Takaful Umum, setelah saya pelajari, Manfaat dan Harganya sangat terjangkau, dan bisa mengcover saya, suami dan anak, dalam satu produk semua yang saya butuhkan ada disini, Produk ini sangat bagus bagi masyarakat yang ingin memiliki Polis Kecelakaan Diri dengan manfaat yang lengkap dan biaya terjangkau.', src: 'testimoni-bu-nadia-surgaina.jpeg'},
        	{title: 'Ibu Wijaya – Ibu Rumah Tangga', subtitle: 'Memilih Orion Penjaminan Indonesia karena Asuransi Syariah Pertama, dengan pelayanan yang cepat dan sudah berbasis IT sehingga polis tidak perlu menunggu waktu yang lama, begitu juga dengan Produk Takaful Baituna yang sangat lengkap  manfaatnya, serta harganya pun sangat terjangkau.', src: 'testimoni-bapak-alisaaf-baituna.jpeg'},
        ],
    }),
    methods: {
    	Zoom (title, src) {
    		this.zoomin.value = true
    		this.zoomin.title = title
    		this.zoomin.src = src
    	}
    }
  }
</script>
